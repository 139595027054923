import {useStore} from 'vuex'
import {
  DELETE_DOCUMENT,
  GET_OR_CREATE_DOCUMENT,
  LOAD_DOCUMENT,
  PATCH_VERSION_ATTRIBUTE,
  PUT_VERSION_ATTRIBUTE
} from '../../../store/graph/operations'
import {useCollection} from './useCollection'
import {useRouter} from 'vue-router'
import {usePermissions} from '@/components/use/usePermissions'
import {handleError} from '@/lib/handleError'
import {useDocumentTemplate} from '@/components/use/serviceAssets/documentEditors/useDocumentTemplate'
import {capitalize, decapitalize} from '../../../../../lib/stringFunctions'
import {SERVICE_ACRONYM} from '../../../../../config'

const debug = false

export function useDocument() {
  const store = useStore()
  const router = useRouter()
  const {getDocumentTemplate} = useDocumentTemplate()
  const {canEdit} = usePermissions()
  const {getCollectionPath, getCollectionUrl, loadCollection} = useCollection()

  const getDocument = (documentAlias, nameProperty = 'name') => {
    let document = store.state.document[documentAlias]
    if (!document) {
      document = Object.values(store.state.document).find(d => d[nameProperty].toLowerCase() === documentAlias.toLowerCase())
    }
    return document
  }
  const getDataType = documentAlias => getDocument(documentAlias)?.dataType ?? 'MarkdownContent' // todo: no default
  const getDocumentId = documentAlias => getDocument(documentAlias)?.repoId
  const getDocumentName = documentAlias => getDocument(documentAlias)?.documentName
  const getDocumentPath = (serviceAlias, collectionAlias, documentAlias) => `${getCollectionPath(serviceAlias, collectionAlias)}/${getDocumentName(documentAlias)}`
  const getDocumentUrl = (serviceAlias, collectionAlias, documentAlias) => `${getCollectionUrl(serviceAlias, collectionAlias)}/${getDocumentName(documentAlias)}`
  const getEncoding = documentAlias => getDocument(documentAlias)?.encoding
  const getDocumentIsLoaded = documentAlias => !!getDocument(documentAlias)?.repoId
  const serviceId = SERVICE_ACRONYM

  const loadDocument = ({
                          createIfNotExists = false,
                          collectionId,
                          collectionName,
                          documentId,
                          documentName,
                          dataType,
                          defaults,
                          refresh = false,
                        }, origin) => new Promise((resolve, reject) => {
    collectionName = decapitalize(collectionName)
    loadCollection({
      collectionId,
      collectionName,
      createIfNotExists,
      refresh
    })
        .then(() => {
          debug && console.log(111, 'collection loaded')
          return resolveDocument({
            createIfNotExists,
            collectionId,
            collectionName,
            documentName,
            documentId,
            dataType,
            defaults,
            refresh,
          }, origin)
              .then(doc => {
                debug && console.log(11111, 'document loaded')
                resolve(doc)
              })
              .catch(err => {
                console.log(238, origin, err)
                handleError(`Could not load ${origin} collection ${collectionName}. ${err.message ?? err}`)
                reject(err)
              })
        })
        .catch(err => {
          console.log(235, origin, err)
          handleError(`Could not load ${origin} Document. ${err.message ?? err}`)
          reject(err)
        })
  })

  const resolveDocument = ({
                             createIfNotExists = false,
                             collectionName,
                             collectionId,
                             documentId,
                             documentName,
                             dataType,
                             defaults = {},
                             refresh = false,
                           }, origin) =>
      new Promise((resolve, reject) => {
        if (getDocumentIsLoaded(collectionId || collectionName) && !refresh) resolve(getDocument(documentId || documentName))
        else {
          if (documentId) {
            debug && console.log(1111, 'loading document by id', {
              collectionId,
              documentId,
            })
            loadDocumentById({
              collectionId,
              documentId,
            }, origin)
                .then(resolve)
                .catch(err => {
                  console.log(236, origin, err)
                  handleError(`Could not load ${origin} Document by id. ${err.message ?? err}`)
                  reject(err)
                })
          } else {
            debug && console.log(1112, 'loading document by path', {
              createIfNotExists,
              collectionName,
              documentName,
              dataType,
              defaults
            })
            getOrCreateDocument({
              createIfNotExists,
              collectionName,
              documentName,
              dataType,
              defaults
            }, origin)
                .then(resolve)
                .catch(err => {
                  console.log(237, origin, err)
                  handleError(`Could not load ${origin} Document by path. ${err.message ?? err}`)
                  reject(err)
                })
          }
        }
      })

  const loadDocumentById = ({
                              collectionId,
                              documentId,
                            }, origin) =>
      new Promise((resolve) => {
        if (!collectionId) return handleError(`Error loading ${origin} Document by id ${documentId}. CollectionId unknown`)
        resolve(
            store.dispatch(LOAD_DOCUMENT, {
              serviceId,
              collectionId,
              documentId,
            })
                .catch(err => handleError(`Error loading ${origin} Document (${getDocumentPath(serviceId, collectionId, documentId)}) by id ${documentId}. ${err.message ?? err}`))
        )
      })

  const getOrCreateDocument = ({
                                 createIfNotExists = false,
                                 serviceName = SERVICE_ACRONYM,
                                 collectionName,
                                 documentName,
                                 dataType = 'MarkdownContent',
                                 defaults,
                               }, origin) =>
      new Promise((resolve) => {
        collectionName = decapitalize(collectionName)
        if (!serviceName) return handleError(`Error loading ${origin} Document by path ${serviceName}/${collectionName}/${documentName}. Service not loadable`)
        if (!collectionName) return handleError(`Error loading ${origin} Document by path ${serviceName}/${collectionName}/${documentName}. Collection not loaded`)
        if (!documentName) return handleError(`Error loading ${origin} Document by path ${serviceName}/${collectionName}/${documentName}. Parameter documentName is required`)

        createIfNotExists &= (canEdit.value ?? false)
        dataType = capitalize(dataType)

        const template = getDocumentTemplate(dataType, defaults)
        resolve(
            store.dispatch(GET_OR_CREATE_DOCUMENT, {
              createIfNotExists,
              serviceName,
              collectionName,
              documentName,
              ...template,
            })
                .catch(err => handleError(`Error loading ${origin} Document (${getDocumentPath(serviceName, collectionName, documentName)}) by path ${serviceName}/${collectionName}/${documentName}. ${err.message ?? err}`))
        )
      })

  const putDocumentAttribute = ({
                                  serviceId,
                                  collectionId,
                                  documentId,
                                  attributeName,
                                  value,
                                }) => store.dispatch(PUT_VERSION_ATTRIBUTE, {
    serviceId,
    collectionId,
    documentId,
    attributeName,
    value
  })
  const patchDocumentAttribute = ({
                                    serviceId,
                                    collectionId,
                                    documentId,
                                    attributeName,
                                    value,
                                  }) => store.dispatch(PATCH_VERSION_ATTRIBUTE, {
    serviceId,
    collectionId,
    documentId,
    attributeName,
    value
  })
  const deleteDocument = ({
                            serviceId,
                            collectionId,
                            documentId
                          }) => store.dispatch(DELETE_DOCUMENT, {
    serviceId,
    collectionId,
    documentId,
  })
  const goToDocument = (serviceAlias, collectionAlias, documentAlias) => router.push(getDocumentPath(serviceAlias, collectionAlias, documentAlias))

  return {
    deleteDocument,
    getDataType,
    getDocument,
    getDocumentId,
    getDocumentName,
    getDocumentIsLoaded,
    getDocumentPath,
    getDocumentUrl,
    getEncoding,
    goToDocument,
    loadDocument,
    loadDocumentById,
    putDocumentAttribute,
    patchDocumentAttribute,
  }
}
