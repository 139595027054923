import { useMarkdown } from '@/components/use/markdown/useMarkdown'

export const useMarkdownArray = props => {
  const { getMarkdownAlias } = useMarkdown(props)
  const getMarkdownArrayAlias = array => {
    return array.map(getMarkdownAlias)
  }
  return {
    getMarkdownArrayAlias,
  }
}
