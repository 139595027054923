import {
  encoding,
  getDefaultPayload
}                              from '@/components/use/serviceAssets/documentEditors/Testimonial/defaults'
import { encode }          from '@/components/service/document/lib/parse'
import { computed, watch } from 'vue'
import { useVersion }      from '@/components/use/serviceAssets/useVersion'
import {SERVICE_ACRONYM} from '../../../../../../../config'

export const useTestimonial = props => {
  const { patchVersionPayloadAttribute, getPayload } = useVersion()
  const imageConfigurationName = props.documentId + 'Image'
  const payload = computed(() => getPayload(props.documentId || props.documentName))

  const testimonial = computed(() =>  payload.value ?? getDefaultContent())
  watch(payload, payload => {
    if (payload) patchVersionPayloadAttribute({
      serviceId: SERVICE_ACRONYM,
      collectionId: props.collectionId,
      documentId: props.documentId,
      attributeName: 'zoomableImage',
     value: imageConfigurationName
    })
  })

  const getDefaultContent = options => {
    const payload = getDefaultPayload(options)
    return encode(payload, encoding)
  }

  return {
    testimonial,
  }
}
