import * as CHCAAUCloudService from './CHCAAUCloudService/defaults'
import * as CenterService from './CenterService/defaults'
import * as ClickToReveal from './ClickToReveal/defaults'
import * as Course from './Course/defaults'
import * as Example from './Example/defaults'
import * as Hint from './Hint/defaults'
import * as MarkdownColumns from './MarkdownColumns/defaults'
import * as MarkdownContent from './MarkdownContent/defaults'
import * as Module from './Module/defaults'
import * as Person from './Person/defaults'
import * as Project from './Projects/defaults'
import * as Publication from './Publication/defaults'
import * as Resource from './Resource/defaults'
import * as Testimonial from './Testimonial/defaults'
import * as ZoomableImage from './ZoomableImage/defaults'

export default {
  CHCAAUCloudService,
  CenterService,
  ClickToReveal,
  Course,
  Example,
  Hint,
  MarkdownColumns,
  MarkdownContent,
  Module,
  Person,
  PersonCard: Person,
  Project,
  Publication,
  Resource,
  Testimonial,
  ZoomableImage,
}
