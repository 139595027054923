import {
  encoding,
  getDefaultPayload
}                                  from '@/components/use/serviceAssets/documentEditors/Person/defaults'
import { encode }                  from '@/components/service/document/lib/parse'
import { computed }           from 'vue'
import { useVersion }              from '@/components/use/serviceAssets/useVersion'

export const usePerson = props => {
  const { getPayload } = useVersion()
  const payload = computed(() => getPayload(props.documentId || props.documentName))

  const person = computed(() => {
    let document = getDefaultContent()
    if (payload.value) {
      document = payload.value
    }
    return document
  })

  const getDefaultContent = options => {
    const payload = getDefaultPayload(options)
    return encode(payload, encoding)
  }

  return {
    person,
  }
}
