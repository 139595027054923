import generateRandomString from '@/lib/generateRandomString'
import { CollectionPipe }   from '@/store/lib/storeHelpers/CollectionPipe'
import UIkit                from 'uikit'
import { isObject }         from '@/lib/getVariableType'

const minimumTimeToLive = 500
const defaultTimeToLive = 5000

const popups = new CollectionPipe('popup')
const typeStatus = {
  info: 'primary',
  error: 'danger',
  warning: 'warning',
  success: 'success'
}
class Popup {
  constructor(pop) {
    if (pop === undefined) throw 'pop must be a string or configuration object'
    try {
      this.message = pop.text ?? pop
      this.status = pop.status || typeStatus[pop.type]
      this.id = pop.id || generateRandomString()
      this.icon = pop.icon
      this.tags = pop.tags ?? []
      this::configureTimeout(pop)
    } catch (err) {
      this.throw(err, this)
    }
  }

  commit() {
    UIkit.notification({
      timeout: 3000,
      message: this.message,
      status: this.status,
      group: this.tags,
    })
  }

  delete() {
    popups.deleteItem(this.id)
  }
}

export function createPopup(pop) {
  const popup = new Popup(pop)
  popup.commit()
}

export function popError(pop, name) {
  if (!isObject(pop)) pop = {text: pop, name}
  pop.type = 'error'
  const popup = new Popup(pop)
  popup.commit()
}

export function popSuccess(pop, name) {
  if (!isObject(pop)) pop = {text: pop, name}
  pop.type = 'success'
  const popup = new Popup(pop)
  popup.commit()
}

function configureTimeout(pop) {
  if (pop.timeout) {
    if (!['error', 'warning'].includes(this.type)) {
      this.timeout = pop.timeout ?? defaultTimeToLive
      if (pop.timeout < minimumTimeToLive) this.timeout = minimumTimeToLive
    }
  }
}

export const popLoginRequired = text => {
  const pop = new Popup({
    id: 'loginRequired',
    text: text || 'Your session expired, please login again.',
    timeout: 3000
  })
  pop.commit()
}

