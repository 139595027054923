import { encode }        from '@/components/service/document/lib/parse'

export const encoding = 'json'
export const aliasAttributePath = 'title'

export const getDefaultPayload = (options = {}) => {
  const payload = options.payload ?? {}
  return {
    title: payload.title ?? 'CHCAA UCloud Service Title',
    subtitle: payload.subtitle ?? 'Optional Subtitle',
    body: payload.body ?? `## Markdown Body
Describe a services here...`,
  }
}
export const getDocumentTemplate = payload => {
  return {
    encoding: 'json',
    assetType: 'Document',
    dataType: 'CHCAAUCloudService',
    collectionName: 'CHCAAuCloudServices',
    content: encode(getDefaultPayload(payload), encoding)
  }
}
