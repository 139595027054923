import { computed }                                        from 'vue'
import { useVersion }                                      from '@/components/use/serviceAssets/useVersion'
import { usePermissions }                                   from '@/components/use/usePermissions'
import { getDocumentTemplate, getDefaultPayload, encoding } from '@/components/use/serviceAssets/documentEditors/MarkdownColumns/defaults'
import { encode }                                           from '@/components/service/document/lib/parse'
import {useStore} from "vuex";

export const useMarkdownColumns = props => {
  const store = useStore()
  const { canEdit } = usePermissions(props)
  const { getPayload, deleteVersion, patchVersionContent } = useVersion()
  const payload = computed(() => getPayload(props.documentId || props.documentName))

  const columns = computed(() => payload.value ?? [])
  const columnCount = computed(() => columns.value?.length ?? 0)

  const getDefaultContent = options => {
    const payload = getDefaultPayload(options)
    return encode(payload, encoding)
  }

  const updateColumns = (index, newValue) => {
    const columns = [...payload.value]
    columns[index] = newValue
    patchVersionContent({
      serviceId: store.state.serviceName,
      collectionId: props.collectionId,
      collectionName: props.collectionName,
      documentId: props.documentId,
      documentName: props.documentName,
      value: columns
    })
  }


  return {
    canEdit,
    columnCount,
    columns,
    deleteVersion,
    updateColumns,
    // collection component
    encoding,
    getDocumentTemplate,
    getDefaultContent,
  }
}
