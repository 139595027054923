import {encoding, getDefaultPayload} from '@/components/use/serviceAssets/documentEditors/Module/defaults'
import {encode} from '@/components/service/document/lib/parse'
import {computed, watch} from 'vue'
import {usePermissions} from '@/components/use/usePermissions'
import {useDocumentNameUrlEncoder} from '@/components/use/useDocumentNameUrlEncoder'
import {useDocument} from '@/components/use/serviceAssets/useDocument'
import {useRouter} from 'vue-router'
import {capitalize} from '../../../../../../../lib/stringFunctions'
import {SERVICE_ACRONYM} from '../../../../../../../config'
import {useStore} from "vuex";
import {decode} from "@/components/service/document/lib/parse";
import {useVersion} from "@u/serviceAssets/useVersion";

export const useModule = props => {
  const store = useStore()
  const router = useRouter()
  const {canEdit} = usePermissions(props)
  const {loadDocument, putDocumentAttribute} = useDocument(props)
  const {deleteVersion} = useVersion(props)
  const collection = computed(() => store.state.collection[props.collectionId] || Object.values(store.state.collection).find(c => c.name === props.collectionName))
  const document = computed(() => store.state.document[props.documentId] || Object.values(store.state.document).find(d => d.name === (props.documentName || props[props.documentNameProperty])))
  const payload = computed(() => {
    let payload
    if (document.value?.repoId) {
      payload = decode(document.value.content, document.value.encoding)
    }
    return payload
  })
  const {decodeName, encodeName} = useDocumentNameUrlEncoder()
  const service = computed(() => store.state.service[store.state.serviceName])

  const module = computed(() => {
    let document = getDefaultContent()
    if (payload.value) {
      document = payload.value
    }
    return document
  })
  const moduleDocument = computed(() => document.value)
  const renameModule = event => {
    const value = encodeName(event.target.innerHTML)
    if (value !== document.value.name)
      putDocumentAttribute({
        serviceId: SERVICE_ACRONYM,
        collectionId: collection.value.repoId,
        documentId: document.value.repoId,
        attributeName: 'name',
        value
      })
  }
  const moduleName = computed(() => {
    return document.value?.name ?? props.moduleName ?? ''
  })
  const decodedModuleName = computed(() => {
    const name = document.value?.name ?? props.moduleName ?? ''
    return capitalize(name && decodeName(name))
  })

  const getDefaultContent = options => {
    const payload = getDefaultPayload(options)
    return encode(payload, encoding)
  }
  const goToModule = () => router.push({
    name: 'courseModule',
    params: {
      courseName: props.courseName,
      moduleName: document.value.name,
    }
  })

  const deleteModule = () => {
    deleteVersion({
      serviceId: store.state.serviceName,
      collectionId: props.collectionId,
      documentId: props.documentId,
    })
  }

  watch(
      service,
      isReady => {
        if (isReady) {
          let collectionId = props.collectionId || collection.value?.repoId || Object.values(store.state.collection).find(c => c.name === props.collectionName)?.repoId
          let documentName = props[props.documentNameProperty || 'documentName']
          loadDocument({
            createIfNotExists: canEdit.value,
            collectionId,
            collectionName: props.collectionName,
            documentName,
            documentId: props.documentId,
            dataType: 'Module',
            defaults: {
              encoding: 'json',
              payload: getDefaultPayload()
            }
          }, 'useModule')
        }
      },
      {immediate: true}
  )

  return {
    decodedModuleName,
    moduleName,
    getDefaultContent,
    goToModule,
    module,
    moduleDocument,
    deleteModule,
    renameModule,
  }
}
