import {encode} from "@c/service/document/lib/parse";

export const encoding = 'json'
export const aliasAttributePath = '#markdown'
export const getDefaultPayload = (options = {}) => options.payload ?? {
  markdown: 'Hint markdown'
}
export const getDocumentTemplate = options => ({
  content: encode(getDefaultPayload(options), encoding),
  encoding,
  assetType: 'Document',
  dataType: 'Hint',
})
