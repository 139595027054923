import {computed} from 'vue'

export function useMarkdown(props) {
  const mdDefault = '<em>empty</em>'
  const md = computed(() => props.md)

  const template = computed(() => {
    return md.value ?? mdDefault
  })

  const getMarkdownAlias = markdown => {
    const lines = (markdown?.split('\n') ?? [])
        .map(line => line.trim())
        .filter(line => line)
    const heading = getFirstHeading(lines)
    return heading || lines[0]
  }
  const getFirstHeading = lines => lines
      .filter(line => line.startsWith('#'))[0]


  return {
    getMarkdownAlias,
    template,
  }
}
