import { computed }                  from 'vue'
import { useComponentConfiguration } from '@/components/use/componentConfiguration/useComponentConfiguration'
import { handleError }               from '@/lib/handleError'
import { encode }                    from '@/components/service/document/lib/parse'
import {
  encoding,
  getDefaultPayload,
  getDocumentTemplate
}                                    from '@/components/use/serviceAssets/documentEditors/ZoomableImage/defaults'
import { useVersion }                from '@/components/use/serviceAssets/useVersion'
import { useDocument }               from '@/components/use/serviceAssets/useDocument'

export const useZoomableImage = props => {

  // generator
  const { getDocumentId } = useDocument(props)
  const { getPayload } = useVersion()
  const documentId = computed(() => getDocumentId(props.documentId || props.documentName))
  const defaultConfiguration = getDefaultPayload()
  const payload = computed(() => getPayload(props.documentId || props.documentName))
  const getDefaultContent = options => {
    const payload = getDefaultPayload(options)
    return encode(payload, encoding)
  }

  // component
  const {
    configuration,
    loadComponentConfiguration,
    patchConfiguration
  } = useComponentConfiguration(props)

  const loadZoomableImage = () => loadComponentConfiguration(props.collectionName)
      .catch(err => handleError(`Error loading ZoomableImage configuration: ${ err.message }`))

  const getRangeMin = scale => (scale - 1) * -10 - 50
  const getRangeMax = scale => (scale - 1) * 10 + 50

  const src = computed(() => configuration.value?.src)
  const zoom = computed(() => configuration.value?.zoom ?? defaultConfiguration.zoom)
  // todo: img.naturalWidth / height
  const offsetHorizontal = computed(() => (offsetHorizontalMax.value && configuration.value?.offsetHorizontal) ?? defaultConfiguration.offsetHorizontal)
  const offsetVertical = computed(() => (offsetHorizontalMin.value && configuration.value?.offsetVertical) ?? defaultConfiguration.offsetVertical)

  const offsetHorizontalMin = computed(() => (zoom.value && getRangeMin(zoom.value)) ?? defaultConfiguration.offsetHorizontalMin)
  const offsetHorizontalMax = computed(() => (zoom.value && getRangeMax(zoom.value)) ?? defaultConfiguration.offsetHorizontalMax)
  const offsetVerticalMin = computed(() => (zoom.value && getRangeMin(zoom.value)) ?? defaultConfiguration.offsetVerticalMin)
  const offsetVerticalMax = computed(() => (zoom.value && getRangeMax(zoom.value)) ?? defaultConfiguration.offsetVerticalMax)
  const style = computed(() => {
    return {
      /*
        scale() is a 2d transformation offering max resolution
        translateZ is a hardware-accelerated 3D transformation treating affected elements as textures
       */
      transform: `scale(${ zoom.value }) translate(${ offsetVertical.value }%, ${ offsetHorizontal.value }%)`,
    }
  })

  const updateSrc = event => patchConfiguration('src', event.target.value)
  const updateZoom = event => patchConfiguration('zoom', event.target.value)
  const updateOffsetHorizontal = event => patchConfiguration('offsetHorizontal', event.target.value)
  const updateOffsetVertical = event => patchConfiguration('offsetVertical', event.target.value)

  // image
  const imageConfiguration = computed(() => {
    let value = { ...defaultConfiguration }
    if (payload.value) {
      value = Object.assign(value, {
        repoId: documentId.value,
        src: src.value,
        zoom: zoom.value,
        offsetHorizontalMin: offsetHorizontalMin.value,
        offsetHorizontal: offsetHorizontal.value,
        offsetHorizontalMax: offsetHorizontalMax.value,
        offsetVerticalMin: offsetVerticalMin.value,
        offsetVertical: offsetVertical.value,
        offsetVerticalMax: offsetVerticalMax.value,
        style: style.value ?? {},
      })
    }
    return value
  })

  return {
    imageConfiguration, // loaded document
    loadZoomableImage, // load document
    updateOffsetHorizontal,
    updateOffsetVertical,
    updateSrc,
    updateZoom,
    // generators
    encoding,
    getDefaultContent,
    getDocumentTemplate,
  }
}
