import { encode }        from '@/components/service/document/lib/parse'

export const encoding = 'json'
export const aliasAttributePath = 'name'

export const getDefaultPayload = (options = {}) => {
  const payload = options.payload ?? {}
  return {
    logoUrl: payload.logoUrl ?? 'https://raw.githubusercontent.com/centre-for-humanities-computing/websiteMedia/main/images/CHCAA/chc_long_tight.png',
    overview: payload.overview ?? `## Course Overview`,
    brief: payload.brief ?? `Brief course advertisement`,
    objectives: payload.objectives ?? '- learn about X',
    prerequisites: payload.prerequisites ?? '[install Y](http://installgit.com)',
    modules: payload.modules ?? [],
  }
}
export const getDocumentTemplate = (payload) => {
  return {
    encoding: 'json',
    assetType: 'Document',
    dataType: 'Course',
    name: 'Unnamed Course',
    content: encode(getDefaultPayload(payload), encoding)
  }
}
