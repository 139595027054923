import { toClipboard }       from '@soerenmartius/vue3-clipboard'
import { popError, popSuccess } from '@/components/use/popups/popupMessages'

export function useMarkdownDeeplinks() {
  const getPageUrl = () => {
    let url = `${ window.location.href }`
    const parts = url.split('#')
    return parts.slice(0, 2).join('#')
  }
  const getFirstElementIdAscending = el => {
    let id = el.getAttribute('id')
    if (!id) {
      id = getFirstElementIdAscending(el.parentElement)
    }
    return id
  }
  const copyDeepLinkUrlToClipboard = $event => {
    const pageUrl = getPageUrl()
    const id = getFirstElementIdAscending($event.target)
    const linkHash = `#${ id }`
    const linkUrl = `${ pageUrl }${ linkHash }`
    toClipboard(linkUrl)
      .then(() => {
        console.log('copied deep link url:', linkUrl)
        popSuccess(`The deep-link was copied to your clipboard.`)
      })
      .catch(err => {
        popError(err)
      })
  }

  return {
    copyDeepLinkUrlToClipboard,
  }
}
