import {
  encoding,
  getDefaultPayload
}                              from '@/components/use/serviceAssets/documentEditors/CenterService/defaults'
import { decode, encode }          from '@/components/service/document/lib/parse'
import { computed, watch } from 'vue'
import { useVersion }      from '@/components/use/serviceAssets/useVersion'
import {SERVICE_ACRONYM} from '../../../../../../../config'
import {useStore} from "vuex";

export const useCenterService = props => {
  const store = useStore()
  const { patchVersionPayloadAttribute } = useVersion()
  const document = computed(() => store.state.document[props.documentId] || Object.values(store.state.document).find(doc => doc.name.toLowerCase() === props.documentName.toLowerCase()))
  const payload = computed(() => {
    let payload
    if (document.value?.repoId) payload = decode(document.value.content, document.value.encoding)
    return payload
  })
  const imageConfigurationName = props.documentId + 'Image'

  const centerService = computed(() =>  payload.value ?? getDefaultContent())
  watch(payload, payload => {
    if (payload) patchVersionPayloadAttribute({
      serviceId: SERVICE_ACRONYM,
      collectionId:props.collectionId,
      documentId: props.documentId,
      attributeName: 'zoomableImage',
      value: imageConfigurationName
    })
  })

  const getDefaultContent = options => {
    const payload = getDefaultPayload(options)
    return encode(payload, encoding)
  }

  return {
    centerService,
  }
}
