import { encode }        from '@/components/service/document/lib/parse'

export const encoding = 'json'
export const aliasAttributePath = 'title'

export const getDefaultPayload = (options = {}) => {
  const payload = options.payload ?? {}
  return {
    title: payload.title ?? 'title',
    name: payload.name ?? 'Name',
    role: payload.role ?? `role`,
    affiliation: payload.affiliation ?? `affiliation`,
    focus: payload.focus ?? 'focus',
    contact: payload.contact ?? `contact`,
  }
}
export const getDocumentTemplate = (payload) => {
  return {
    encoding: 'json',
    assetType: 'Document',
    dataType: 'Publication',
    content: encode(getDefaultPayload(payload), encoding)
  }
}
