import {encoding, getDefaultPayload} from '@/components/use/serviceAssets/documentEditors/Course/defaults'
import {decode, encode} from '@/components/service/document/lib/parse'
import {computed, watch} from 'vue'
import {useVersion} from '@/components/use/serviceAssets/useVersion'
import {useDocument} from '@/components/use/serviceAssets/useDocument'
import {useCollection} from '@/components/use/serviceAssets/useCollection'
import {useDocumentNameUrlEncoder} from '@/components/use/useDocumentNameUrlEncoder'
import {useRouter} from 'vue-router'
import {useStore} from "vuex";
import {SERVICE_ACRONYM} from '../../../../../../../config'

export const useCourse = props => {
  const store = useStore()
  const router = useRouter()
  const {loadCollection} = useCollection()
  const {loadDocument, putDocumentAttribute} = useDocument(props)
  const {patchVersionPayloadAttribute} = useVersion()

  const {decodeName, encodeName} = useDocumentNameUrlEncoder()
  const service = computed(() => store.state.service[store.state.serviceName])
  const collection = computed(() => store.state.collection[props.collectionId] || Object.values(store.state.collection).find(c => c.name === props.collectionName))
  const document = computed(() => store.state.document[props.documentId] || Object.values(store.state.document).find(d => d.name === (props.documentName || props[props.documentNameProperty])))
  const payload = computed(() => {
    let payload = ''
    if (document.value?.repoId) payload = decode(document.value.content, document.value.encoding)
    return payload
  })

  const course = computed(() => payload?.value)
  const loadModuleCollection = () => {
    loadCollection({
      collectionName: 'modules',
      refresh: true,
    })
  }
  const modules = computed(() => {
    let modules = []
    if (document.value.repoId) {
      modules = Object.values(store.state.document).filter(d => course.value.modules.includes(d.repoId))
    }
    return modules
  })
  const moduleCollectionRepoId = computed(() => Object.values(store.state.collection).find(c => c.name === 'modules')?.repoId)
  const availableModuleNames = computed(() => {
    const storeDocuments = Object.values(store.state.document)
    if (!storeDocuments || !storeDocuments.length) return []

    console.log(22, modules.value.map(d => d.name))
    return storeDocuments
        .filter(doc => doc.parentRepoId === moduleCollectionRepoId.value)
        .filter(doc => !modules.value.map(d => d.name).includes(doc.name))
        .map(doc => doc.name)

  })
  const getCourseModules = courseRepoId => Object.values(store.state.document).filter(doc => doc.repoId === courseRepoId)
  const courseDocument = computed(() => document.value)
  const decodedCourseName = computed(() => {
    const name = document.value?.name ?? props.courseName ?? ''
    return name && decodeName(name)
  })
  const renameCourse = event => {
    const value = encodeName(event.target.innerHTML)
    if (value !== document.value.name)
      putDocumentAttribute({
        serviceId: SERVICE_ACRONYM,
        collectionId: props.collectionId,
        documentId: document.value.repoId,
        attributeName: 'name',
        value
      })
  }
  const getDefaultContent = options => {
    const payload = getDefaultPayload(options)
    return encode(payload, encoding)
  }
  const goToCourse = () => router.push({
    name: 'course',
    params: {
      courseName: document.value.name,
    }
  })
  const patchCourseModules = update => patchVersionPayloadAttribute({
      serviceId: SERVICE_ACRONYM,
      collectionId: collection.value.repoId,
      documentId: courseDocument.value.repoId,
      attributeName: 'modules',
      value: update
    })
      .catch(e => console.log(44, 'shit happened:', e))

  watch(
      service,
      isReady => {
        if (isReady) {
          let documentName = props[props.documentNameProperty || 'documentName']
          loadDocument({
            collectionId: props.collectionId,
            collectionName: props.collectionName,
            documentName,
            documentId: props.documentId,
            dataType: 'Course',
            defaults: {
              encoding: 'json',
              payload: getDefaultPayload()
            }
          }, 'useCourse')
        }
      },
      {immediate: true}
  )
  watch(course, course => {
    if (!course) return
    loadModuleCollection()
  }, {immediate: true})

  return {
    availableModuleNames,
    course,
    courseDocument,
    decodedCourseName,
    getCourseModules,
    getDefaultContent,
    goToCourse,
    modules,
    patchCourseModules,
    renameCourse,
  }
}
