import {decode, encode} from '@/components/service/document/lib/parse.js'
import {useStore} from 'vuex'
import {
  DELETE_DOCUMENT_VERSION,
  LOAD_DOCUMENT_VERSION,
  PATCH_VERSION_ATTRIBUTE,
  PUT_VERSION_ATTRIBUTE
} from '@/store/graph/operations/index.js'
import {useDocument} from '@/components/use/serviceAssets/useDocument.js'
import {useMarkdown} from '@/components/use/markdown/useMarkdown.js'
import {useMarkdownArray} from '@/components/use/markdown/useMarkdownArray.js'
import {computed} from "vue";
import {withoutPrefix} from "../../../../../lib/stringFunctions";
import datatypes from './documentEditors/documentEditors'

export const useVersion = () => {
  const store = useStore()
  const {
    getDocumentIsLoaded,
    getDataType,
    getDocument,
    getDocumentName,
    getEncoding,
  } = useDocument()
  const getContent = documentAlias => (store.state.document[documentAlias] || Object.values(store.state.document).find(d => d.name === documentAlias))?.content
  const getPayload = documentAlias => decode(getContent(documentAlias), getEncoding(documentAlias))

  const {getMarkdownAlias} = useMarkdown()
  const {getMarkdownArrayAlias} = useMarkdownArray()

  // const getVersionNames = computed(() => filterCollectionItems('versionNames', 'repoId', props.assetId.value))

  // const createNewVersion = (name, content) => store.dispatch(CREATE_DOCUMENT_VERSION, {
  //   name,
  //   content
  // })
  const deleteVersion = ({
                           serviceId,
                           collectionId,
                           documentId,
                           versionId = '',
                         }) => {
    store.dispatch(DELETE_DOCUMENT_VERSION, {
      serviceId,
      collectionId,
      documentId,
      versionId
    })
  }
  const loadVersions = (assetType, assetId) => store.dispatch(LOAD_DOCUMENT_VERSION, {
    assetType,
    assetId
  })
  const putVersionContent = ({
                               serviceId,
                               collectionId,
                               documentId,
                               versionId,
                               value
                             }) => new Promise((resolve, reject) => {
    if (value !== getPayload(documentId)) resolve()

    const content = encode(value, getEncoding(documentId))
    store.dispatch(PUT_VERSION_ATTRIBUTE, {
      serviceId,
      collectionId,
      documentId,
      versionId,
      attributeName: 'content',
      value: content,
    })
        .then(resolve)
        .catch (reject)
  })

const patchVersionContent = ({
                               serviceId,
                               collectionId,
                               documentId,
                               versionId,
                               value
                             }) => new Promise((resolve, reject) => {
  if (value === getPayload(documentId)) resolve()
  else {
    const document = computed(() => store.state.document[documentId])
    const content = encode(value, document.value.encoding)

    store.dispatch(PATCH_VERSION_ATTRIBUTE, {
      serviceId,
      collectionId,
      documentId,
      versionId,
      attributeName: 'content',
      value: content,
    })
        .then(resolve)
        .catch(err => {
          console.log(777, err)
          reject(err)
        })
  }
})

const getPayloadAlias = (documentId, aliasAttributeName) => {
  /*
      alias = headings > name > id
   */
  aliasAttributeName = aliasAttributeName || datatypes?.[getDataType(documentId)]?.aliasAttributePath
  let alias = documentId
  if (getDocumentIsLoaded(documentId)) {
    if (aliasAttributeName?.startsWith('.')) alias = getDocumentAttributeAlias(documentId, withoutPrefix(aliasAttributeName, '.'))
    else
      switch (getEncoding(documentId)) {
        case 'markdown':
          alias = getMarkdownAlias(getPayload(documentId))
          break
        case 'markdownArray':
          alias = getMarkdownArrayAlias(getPayload(documentId))
          break
        case 'json':
          if (aliasAttributeName?.startsWith('#')) alias = getJSONMarkdownAttributeAlias(documentId, withoutPrefix(aliasAttributeName, '#'))
          else alias = getJSONFileAlias(documentId, aliasAttributeName)
          break
        default:
          alias = getDocumentName(documentId) || 'generic document'
      }
  }
  return alias
}

const getDocumentAttributeAlias = (documentAlias, aliasAttributeName) => getDocument(documentAlias)?.[aliasAttributeName]
const getJSONFileAlias = (documentAlias, aliasAttributeName) => aliasAttributeName ? getPayload(documentAlias)?.[aliasAttributeName] : getDocumentName(documentAlias)
const getJSONMarkdownAttributeAlias = (documentAlias, aliasAttributeName) => getMarkdownAlias(getPayload(documentAlias)?.[aliasAttributeName] ?? '')

const patchVersionPayloadAttribute = ({
                                        serviceId,
                                        collectionId,
                                        documentId,
                                        versionId,
                                        attributeName,
                                        value
                                      }) => new Promise((resolve, reject) => {
  const patched = {...getPayload(documentId)}
  patched[attributeName] = value
  const content = encode(patched, getEncoding(documentId) ?? 'json') // todo: no fallback

  store.dispatch(PATCH_VERSION_ATTRIBUTE, {
    serviceId,
    collectionId,
    documentId,
    versionId,
    attributeName: 'content',
    value: content,
  })
      .then(resolve)
      .catch(reject)
})

return {
  getContent,
  // createNewVersion,
  deleteVersion,
  loadVersions,
  getPayload,
  patchVersionContent,
  getPayloadAlias,
  putVersionContent,
  patchVersionPayloadAttribute,
  // getVersionNames,
  // getVersion,
}
}
