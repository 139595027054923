import documentEditors from './documentEditors'
import { handleError }      from '@/lib/handleError'
import { capitalize } from '../../../../../../lib/stringFunctions'

/*
    generate default document
     - document property template from defaultDocuments
     - default content from store.state.configuration
 */
export const useDocumentTemplate = () => {
  const getDocumentTemplate = (dataType, options) => {
    verifyDataType(dataType)
    dataType = capitalize(dataType)
    verifyDataTypeController(dataType)
    verifyDataTypeControllerDocumentGenerator(dataType)
    const editor = documentEditors[dataType]
    const template = editor?.getDocumentTemplate(options)
    return template
  }

  const verifyDataType = dataType =>
    !dataType && handleError(`Error generating document template. dataType is required`)

  const verifyDataTypeController = dataType =>
    !documentEditors[dataType] && handleError(`Error generating document template. Default document controller for dataType ${ dataType } is undefined`)

  const verifyDataTypeControllerDocumentGenerator = dataType =>
    !documentEditors[dataType].getDocumentTemplate && handleError(`Error generating document template. Default document generator for dataType ${ dataType } is undefined`)

  return {
    getDocumentTemplate, // todo: find references to getDefaultDocument  (old name)5
  }
}
