import {useStore} from 'vuex'
import {
  CREATE_DOCUMENT,
  GET_OR_CREATE_COLLECTION,
  LOAD_COLLECTION,
  PUT_COLLECTION_ATTRIBUTE
} from '@/store/graph/operations/index.js'
import {popError} from '@/components/use/popups/popupMessages.js'
import {useService} from './useService'
import {useRouter} from 'vue-router'
import {usePermissions} from '@/components/use/usePermissions.js'
import {handleError} from '@/lib/handleError'
import dayjs from 'dayjs'
import {SERVICE_ACRONYM} from '../../../../../config'
// const debug = false
export const useCollection = () => {
  const store = useStore()
  const router = useRouter()
  const {canEdit} = usePermissions()
  const {assertServiceIsLoaded, getServicePath, getServiceUrl} = useService()
  const getCollection = collectionAlias => {
    let collection = store.state.collection?.[collectionAlias]
    if (!collection) {
      collection = Object.values(store.state.collection).find(item => item.name === collectionAlias)
    }
    return collection
  }
  const getCollectionName = collectionAlias => getCollection(collectionAlias)?.name
  const getDocuments = collectionAlias => {
    let docs = []
    const collection = getCollection(collectionAlias)
    if (collection) {
      if (collection.documentSequence) {
        // extract into method
        if (Object.values(store.state.document).length) {
          collection.documentSequence.forEach(repoId => {
            const doc = store.state.document[repoId]
            if (doc) docs.push(doc)
          })
        }
      } else {
        docs = Object.values(store.state.document)
            .filter(doc => doc.parentRepoId === collection.repoId)
            .reverse() // newest first
      }
    }
    return docs
  }
  const getCollectionId = collectionAlias => getCollection(collectionAlias)?.repoId
  const getCollectionPath = (serviceAlias, collectionAlias) => `${getServicePath(serviceAlias)}/${getCollectionName(collectionAlias)}`
  const getCollectionUrl = (serviceAlias, collectionAlias) => `${getServiceUrl(serviceAlias)}/${getCollectionName(collectionAlias)}`

  const assertServiceIsReady = () => {
    try {
      assertServiceIsLoaded()
    } catch (err) {
      console.log(240, err.message)
      handleError(`useCollection service not loaded Error. ${err.message}`)
    }
  }
  const loadCollection = ({
                            collectionId,
                            collectionName,
                            defaults,
                            createIfNotExists = false,
                            refresh = false,
                          } = {}) => new Promise((resolve, reject) => {
    const serviceId = SERVICE_ACRONYM
    const collection = store.state.collection[collectionId] || Object.values(store.state.collection).find(c => c.name === collectionName)

    if (collection?.repoId && !refresh) resolve(collection)
    else {
      assertServiceIsReady()
      if (!collectionName) {
        loadCollectionById({ // todo: return default configuration / document with collection
          serviceId,
          collectionId,
          defaults,
        })
            .then(resolve)
            .catch(err => {
              console.log(238, err)
              handleError(`Could not load collection by id. ${err.message ?? err}`)
              reject(`Could not load collection by id. ${err.message ?? err}`)
            })
      } else {
        createIfNotExists &= (canEdit.value ?? false)
        loadCollectionByPath({
          serviceName: serviceId,
          collectionName,
          refresh,
          defaults,
          createIfNotExists,
        })
            .then(resolve)
            .catch(err => {
              console.log(239, err)
              handleError(`Could not load collection by name. ${err.message ?? err}`)
              reject(`Could not load collection by name. ${err.message ?? err}`)
            })
      }
    }
  })
  const loadCollectionById = ({
                                serviceId,
                                collectionId,
                                defaults,
                              } = {}) =>
      new Promise((resolve, reject) => {
        if (!collectionId) return reject(`Error loading Collection by id (${collectionId}). Missing parameter collectionId`)
        else {
          store.dispatch(LOAD_COLLECTION, {
            defaults,
            serviceId,
            collectionId,
          })
              .then(resolve)
              .catch(err => reject(`Error loading Collection (${getCollectionPath(serviceId, collectionId)}) by id ${collectionId}. ${err.message ?? err}`))
        }
      })

  const loadCollectionByPath = ({
                                  serviceName,
                                  collectionName,
                                  refresh,
                                  defaults = {},
                                  createIfNotExists = false,
                                } = {}) =>
      new Promise((resolve, reject) => {
        if (!collectionName) return reject(`Error loading Collection by path (${serviceName}/${collectionName}). Missing parameter collectionName`)
        else {
          store.dispatch(GET_OR_CREATE_COLLECTION, {
            ...defaults,
            createIfNotExists: createIfNotExists && canEdit && !refresh,
            serviceName,
            collectionName,
          })
              .then(resolve)
              .catch(err => {
                console.log(241, err)
                handleError(`Could not load collection (${getCollectionPath(serviceName, collectionName)}) by path ${serviceName}/${collectionName}. ${err.message ?? err}`)
                reject(err)
              })
        }
      })

  const createCollectionDocument = ({
                                      serviceId,
                                      collectionId,
                                      documentName,
                                      versionName,
                                      encoding,
                                      dataType,
                                      content
                                    }) => {
    documentName = documentName || `New Document (${dayjs().format('YYYY-MM-DD HH-mm-ss')})`

    if (!collectionId) popError({text: 'useCollection.createCollectionDocument.missingParameter.collectionId'}, 'useCollection.createCollectionDocument.error')
    if (!dataType) popError({text: 'useCollection.createCollectionDocument.missingParameter.dataType'}, 'useCollection.createCollectionDocument.error')

    const context = {
      serviceId,
      collectionId,
      documentName,
      dataType,
      encoding,
    }
    if (versionName) context.versionName = versionName
    if (content) context.content = content
    return store.dispatch(CREATE_DOCUMENT, context)
  }
  const getDocumentAliases = (serviceAlias, collectionAlias) => getDocuments(serviceAlias, collectionAlias).map(doc => doc.name || doc.id)

  const putDocumentSequence = (collectionId, value) => store.dispatch(PUT_COLLECTION_ATTRIBUTE, {
    serviceId: SERVICE_ACRONYM,
    collectionId,
    attributeName: 'documentSequence',
    value
  })
  const goToCollection = (serviceAlias, collectionAlias) => router.push(getCollectionPath(serviceAlias, collectionAlias))

  return {
    createCollectionDocument,
    getCollectionId,
    getCollectionPath,
    getCollectionUrl,
    getDocumentAliases,
    getDocuments,
    goToCollection,
    loadCollection,
    putDocumentSequence,
  }
}
