import { useStore } from 'vuex'
import { popError } from "@u/popups/popupMessages";
import { LOAD_SERVICE } from '@/store/graph/operations'

const baseUrl = process.env.baseUrl

export const useService = () => {
  const store = useStore()

  const getService = serviceAlias => {
    serviceAlias = serviceAlias || store.state.serviceName
    let service = store.state.service[serviceAlias]
    if (!service) {
      service = Object.values(store.state.service).find(item => item.name === serviceAlias)
    }
    return service
  }
  const getServiceId = serviceAlias => getService(serviceAlias)?.repoId
  const getServiceName = serviceAlias => getService(serviceAlias)?.name
  const getServicePath = serviceAlias => `/service/${getServiceName(serviceAlias)}`
  const getServiceUrl = serviceAlias => `${baseUrl}${getServicePath(serviceAlias)}`

  const getServiceIsLoaded = serviceAlias => getService(serviceAlias)?.repoId
  const loadService = (serviceName, refresh) => new Promise((resolve, reject) => {

    if (getService(serviceName)?.repoId && !refresh) resolve(getService(serviceName))
    else {
      store.dispatch(LOAD_SERVICE, {
        serviceName
      })
        .then(resolve)
        .catch(err => {
          popError({message: `Could not load Service. ${err.message ?? err}`})
          reject(err)
        })
    }
  })

  const assertServiceIsLoaded = serviceAlias => {
    if (!getServiceIsLoaded(serviceAlias)) throw new Error(`Service (${serviceAlias}) not loaded`)
  }

  return {
    assertServiceIsLoaded,
    loadService,
    getService,
    getServiceId,
    getServiceName,
    getServicePath,
    getServiceUrl,
    getServiceIsLoaded,
  }
}
