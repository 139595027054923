import { encode }        from '@/components/service/document/lib/parse'

export const encoding = 'json'
export const aliasAttributePath = 'name'

export const getDefaultPayload = (options = {}) => {
  const payload = options.payload ?? {}
  return {
    logoUrl: payload.logoUrl ?? 'https://raw.githubusercontent.com/centre-for-humanities-computing/websiteMedia/main/images/CHCAA/chc_long_tight.png',
    overview: payload.overview ?? `## Module Overview`,
    brief: payload.brief ?? `Brief module summary`,
    codingCafe: '__Coding Cafe:__ Today\'s code-along topic',
    time: Date.now(),
    location: 'Auditorium',
    readings: [],
    assignments: [],
    documentArrayId: null
  }
}
export const getDocumentTemplate = (payload) => {
  return {
    encoding: 'json',
    assetType: 'Document',
    dataType: 'Module',
    name: 'Unnamed Module',
    content: encode(getDefaultPayload(payload), encoding)
  }
}
