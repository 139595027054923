import markdownInline from '@/components/use/serviceAssets/documentEditors/lib/markdownInline'

export const encoding = 'markdown'

export const getDefaultPayload = (options = {}) => options.payload ?? markdownInline
export const getDocumentTemplate = options => {
  return {
    encoding: 'markdown',
    assetType: 'Document',
    dataType: 'MarkdownContent',
    content: getDefaultPayload(options),
  }
}
