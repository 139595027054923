import { encode }                 from '@/components/service/document/lib/parse'
import markdownInline             from '@/components/use/serviceAssets/documentEditors/lib/markdownInline'
import { createArrayWithDefaultValue } from '../../../../../../../lib/arrayFunctions'

export const encoding = 'markdown'

export const getDefaultPayload = (options = {}) => {
  options.columnCount = options.columnCount ?? 1
  options.columnPayload = options.columnPayload ?? markdownInline
  const payload = options.payload ?? createArrayWithDefaultValue(options.columnPayload, options.columnCount)
  return payload
}

export const getDocumentTemplate = options => { // todo: save generator as ES node
  return {
    encoding,
    assetType: 'Document',
    dataType: 'MarkdownColumns',
    content: encode(options.payload ?? getDefaultPayload(options), encoding),
  }
}


