import { encode } from '@/components/service/document/lib/parse'

export const encoding = 'json'

export const getDefaultPayload = (options = {}) => {
  const payload = options.payload ?? {}
  return {
    src: payload.src ?? 'https://raw.githubusercontent.com/centre-for-humanities-computing/websiteMedia/main/images/CHCAA/Logo.png',
    alt: payload.alt ?? 'non-descript image',
    zoom: payload.zoom ?? 1,
    offsetHorizontal: payload.offsetHorizontal ?? 0,
    offsetHorizontalMin: payload.offsetHorizontalMin ?? 0,
    offsetHorizontalMax: payload.offsetHorizontalMax ?? 0,
    offsetVertical: payload.offsetVertical ?? 0,
    offsetVerticalMin: payload.offsetVerticalMin ?? 0,
    offsetVerticalMax: payload.offsetVerticalMax ?? 0,
  }
}
export const getDocumentTemplate = (options = {}) => {
  return {
    encoding,
    assetType: 'Document',
    dataType: 'ZoomableImage',
    content: encode(getDefaultPayload(options), encoding),
  }
}
